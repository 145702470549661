import Link from "@/components/ui/Link";
import {
  getBlogPostUrl,
  getBlogCategoryUrl,
  getBlogAuthorUrl,
} from "@/lib/blog";
import Pagination from "@/components/Blog/BlogArchiveListSection/Pagination";
import { getReadableImageUrl } from "@/lib/directus";
import Image from "next/image";
import { sanitizeHTML } from "@/lib/utils";
import { htmlToPlainText } from "../utils";

export interface BlogArchiveListSectionData {
  posts: {
    id: string;
    title: string;
    slug: string;
    content: string;
    author: {
      slug: string;
      title: string;
    };
    categories: {
      blog_categories_id: {
        slug: string;
        title: string;
      };
    }[];

    published_at: string;
    featured_image: {
      id: string;
      filename_download: string;
    };
  }[];
  total: number;
  archiveSlug: string;
  archiveDate: string;
  totalPages: number;
  currentPage: number;
  left_column_data: any;
}

interface BlogArchiveListSectionProps {
  data: BlogArchiveListSectionData;
}

export default function BlogArchiveListSection({
  data,
}: BlogArchiveListSectionProps) {
  return (
    <>
      {!data.posts.length && (
        <div className="text-center py-16">
          <p className="text-neutral-600">No posts found for this archive period.</p>
        </div>
      )}
      <section className="py-8 md:py-12 lg:py-16">
        <div className="container mx-auto max-w-[950px]">
          <div className="space-y-16">
            {data.posts.map((post) => (
              <article
                key={post.id}
                className="bg-white shadow-sm rounded-lg overflow-hidden mb-16"
              >
                {post.featured_image && (
                  <Link href={getBlogPostUrl(post.slug)} className="block mb-4">
                    <div className="relative aspect-[16/9]">
                      <Image
                        src={getReadableImageUrl(
                          post.featured_image.id,
                          post.featured_image.filename_download
                        )}
                        alt={htmlToPlainText(post.title)}
                        fill
                        className="object-cover"
                        quality={95}
                        loading={"lazy"}
                      />
                    </div>
                  </Link>
                )}
                <h2 className="font-freightBig text-2xl md:text-3xl mb-3">
                  <Link
                    href={getBlogPostUrl(post.slug)}
                    className="text-neutral-900"
                    dangerouslySetInnerHTML={sanitizeHTML(post.title)}
                  />
                </h2>

                <div className="text-xs text-neutral-600 mb-4">
                  <span className="mr-1">
                    by{" "}
                    <Link href={getBlogAuthorUrl(post.author.slug)}>
                      {post.author.title}
                    </Link>
                  </span>
                  <span className="mx-1">|</span>
                  <time dateTime={post.published_at} className="mx-1">
                    {new Date(post.published_at).toLocaleDateString("en-US", {
                      year: "numeric",
                      month: "long",
                      day: "numeric",
                    })}
                  </time>
                  <span className="mx-1">|</span>
                  {post.categories.map((category, index) => (
                    <>
                      <Link
                        key={category.blog_categories_id.slug}
                        href={getBlogCategoryUrl(
                          category.blog_categories_id.slug
                        )}
                        className="ml-1"
                      >
                        {category.blog_categories_id.title}
                      </Link>
                      {index < post.categories.length - 1 && <span>,</span>}
                    </>
                  ))}
                </div>

                <div className="prose prose-neutral max-w-none">
                  <p className="text-neutral-600 text-base font-aileron line-clamp-3">
                    {htmlToPlainText(post.content)}
                  </p>
                </div>
              </article>
            ))}
          </div>

          <Pagination
            currentPage={data.currentPage || 1}
            totalPages={data.totalPages}
            baseUrl={`/${process.env.NEXT_PUBLIC_BLOG_SLUG}/${process.env.NEXT_PUBLIC_BLOG_ARCHIVE_SLUG}/${data.archiveSlug}`}
          />
        </div>
      </section>
    </>
  );
}
