import Image from "next/image";
import { sanitizeHTML } from "@/lib/utils";
import {
  Carousel,
  CarouselContent,
  CarouselItem,
  CarouselNext,
  CarouselPrevious,
} from "@/components/ui/carousel";
import Link from "@/components/ui/Link";
import { getReadableImageUrl } from "@/lib/directus";
import { getBlogPostUrl } from "@/lib/blog";

interface BlogPreviewData {
  heading?: {
    text?: string;
    title?: string;
  };
  subheading?: {
    text?: string;
    title?: string;
  };
  content_collection?: {
    id: string;
    blog_posts_id: {
      title: string;
      slug: string;
      featured_image: {
        id: string;
        filename_download: string;
      };
    };
  }[];
}

export const fields = [
  "heading.text",
  "heading.title",
  "subheading.text", 
  "subheading.title",
  "content_collection.id",
  "content_collection.blog_posts_id.title",
  "content_collection.blog_posts_id.slug",
  "content_collection.blog_posts_id.featured_image.id",
  "content_collection.blog_posts_id.featured_image.filename_download",
] as const;

interface BlogPreviewProps {
  data?: BlogPreviewData;
}

export default function BlogPreview({ data }: BlogPreviewProps) {
  return (
    <section id="blog-preview" className="w-full py-16">
      <div className="container max-w-[1183px]">
        <span className="text-sm font-bold">{data?.heading?.text || ''}</span>
        <h2 className="text-3xl lg:text-4xl font-freightBig my-6">
          {data?.heading?.title || ''}
        </h2>
        <span className="text-sm font-bold">
          {data?.subheading?.text || ''}
        </span>
      </div>
      <div className="container xl:px-0 max-w-[1183px] xl:max-w-none mt-16">
        <Carousel opts={{ align: "start", dragFree: true }}>
          <CarouselContent className="-ml-4">
            <CarouselItem className="pl-4 hidden xl:block xl:basis-[calc((100vw-1183px+160px)/2)] 2xl:basis-[calc((100vw-1183px+190px)/2)]" />
            {data?.content_collection?.map((post) => {
              const title = post.blog_posts_id?.title || '';
              const slug = post.blog_posts_id?.slug || '';
              const imageId = post.blog_posts_id?.featured_image?.id || '';
              const imageFilename = post.blog_posts_id?.featured_image?.filename_download || '';
              const blogUrl = getBlogPostUrl(slug);
              
              return (
                <CarouselItem
                  className="pl-4 basis-full xl:basis-[1008px]"
                  key={post.id}
                >
                  <Link href={blogUrl} className="group">
                    <div className="relative overflow-hidden rounded-lg h-[300px] md:h-[360px] lg:h-[480px]">
                      <Image
                        src={getReadableImageUrl(imageId, imageFilename)}
                        alt={title}
                        width={1008}
                        height={480}
                        className="size-full object-cover transition-transform duration-300 group-hover:scale-105"
                        
                        quality={95}
                        loading={"lazy"}
                      />
                      <div className="absolute inset-0 bg-gradient-to-b from-transparent via-transparent to-black/80" />
                      <div className="absolute bottom-0 left-0 right-0 p-6">
                        <div className="bg-white/60 backdrop-blur-sm p-4 lg:p-6 rounded-lg group-hover:bg-white/90 transition-all border-2 border-white/70">
                          <h3 className="text-xl md:text-2xl lg:text-3xl font-freightBig mb-4 text-black  transition-colors"
                          dangerouslySetInnerHTML={sanitizeHTML(title)}
                          />
                          <button className="text-sm font-bold text-black  transition-colors">
                            Read Full Post
                          </button>
                        </div>
                      </div>
                    </div>
                  </Link>
                </CarouselItem>
              );
            })}
          </CarouselContent>
          <div className="flex justify-between gap-4 mt-10 container max-w-[1183px] mx-auto">
            <CarouselPrevious className="static size-12 border-2 border-black bg-transparent hover:bg-black hover:text-white transition-colors text-black translate-y-0" />
            <CarouselNext className="static size-12 border-2 border-black bg-transparent hover:bg-black hover:text-white transition-colors text-black translate-y-0" />
          </div>
        </Carousel>
      </div>
    </section>
  );
}
