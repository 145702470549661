import Image from 'next/image'
import Link from '@/components/ui/Link'
import { getReadableImageUrl } from '@/lib/directus'
import { getBlogPostUrl, getBlogCategoryUrl } from '@/lib/blog'
import Pagination from '@/components/Blog/BlogIndexSection/Pagination'
import { htmlToPlainText } from '../utils'
import { useState, useEffect } from 'react'
import BlogTabs from '../BlogTabs/BlogTabs'

export interface BlogIndexSectionData {
	posts: {
		id: string
		title: string
		slug: string
		content: string
		published_at: string
		categories: {
			id: string
			blog_categories_id: {
				id: string
				slug: string
				title: string
			}
		}[]
		featured_image: {
			id: string
			filename_disk: string
		}
	}[]
	total: number
	totalPages: number
	currentPage: number
	categories: {
		id: string
		slug: string
		title: string
	}[]
	archives: {
		date: string
		count: number
	}[]
	left_column_data?: any
}

interface BlogIndexSectionProps {
	data: BlogIndexSectionData
}

export default function BlogIndexSection({ data }: BlogIndexSectionProps) {

	const [mounted, setMounted] = useState(false);
	const displayPosts = data.posts;

	useEffect(() => {
		setMounted(true);
	}, [data]);


	const formatDate = (date: string) => {
		try {
			return new Date(date).toLocaleDateString('en-US', {
				year: 'numeric',
				month: 'long',
				day: 'numeric',
				timeZone: 'UTC'
			})
		} catch (e) {
			return date
		}
	}


	return (
		<section className='pt-0 pb-12 md:pb-16 lg:pb-20'>
			<div className='container mx-auto max-w-[1400px] px-4'>
				<div className='flex flex-col'>
					
					<div className='order-2 md:order-1 mb-md-12 mt-[80px] md:mt-0'>
						<BlogTabs data={
							{
								categories: data.categories,
								archives: data.archives
							}
						} />
					</div>
					
					<div className='order-1 md:order-2'>
						<div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-10'>
							{displayPosts.map(post => (
								<article
									key={post.id}
									className='bg-white rounded-lg border border-neutral-200 shadow-sm overflow-hidden flex flex-col h-full'
								>
									<Link href={getBlogPostUrl(post.slug)} className='block'>
										<div className='relative aspect-[16/10] w-full'>
											<Image
												src={
													post.featured_image
														? getReadableImageUrl(
																post.featured_image.id,
																post.featured_image.filename_download
														  )
														: '/images/blog-placeh2.webp'
												}
												alt={htmlToPlainText(post.title) || ''}
												fill
												className='object-cover'
												sizes='(max-width: 768px) 100vw, (max-width: 1024px) 50vw, 33vw'
												quality={95}
												loading={'lazy'}
											/>
										</div>
									</Link>

									<div className='p-8 flex flex-col flex-grow'>
										<div className='text-sm text-neutral-600 mb-2' suppressHydrationWarning>
											<Link
												href='/about/dr-julian-de-silva-mbbs-md/'
												className='text-primary hover:text-primary/80'
											>
												Dr De Silva
											</Link>
											<span className='mx-2'>•</span>
											<time dateTime={post.published_at}>
												{mounted ? formatDate(post.published_at) : ''}
											</time>
										</div>

										<h2 className='font-freightBig text-[1.5rem] md:text-2xl mb-4'>
											<Link
												href={getBlogPostUrl(post.slug)}
												className='text-neutral-900 hover:text-primary'
											>
												{htmlToPlainText(post.title)}
											</Link>
										</h2>

										<div className='prose prose-neutral max-w-none mb-6 flex-grow'>
											<p className='text-neutral-600 text-base line-clamp-[6]'>
												{htmlToPlainText(post.content)}
											</p>
										</div>

										<Link
											href={getBlogPostUrl(post.slug)}
											className='inline-flex items-center justify-center px-6 py-2 bg-neutral-900 text-white font-medium hover:bg-neutral-800 transition-colors rounded-full mt-auto self-start'
										>
											READ MORE
										</Link>
									</div>
								</article>
							))}
						</div>

						<Pagination
							currentPage={data.currentPage || 1}
							totalPages={data.totalPages}
							baseUrl={`/${process.env.NEXT_PUBLIC_BLOG_SLUG}`}
						/>
					</div>
				</div>
			</div>
		</section>
	)
}
