import Link from "@/components/ui/Link";
import React from "react";

interface PaginationProps {
    currentPage: number;
    totalPages: number;
    baseUrl: string;
  }
  
 export default function Pagination({ currentPage, totalPages, baseUrl }: PaginationProps) {

    return (
      <div className="flex justify-between items-center mt-12 font-source-sans font-bold">
        {currentPage < totalPages && (
          <Link 
            href={`${baseUrl}/${process.env.NEXT_PUBLIC_BLOG_PAGE_SLUG}/${currentPage + 1}`}
            className="text-neutral-900 transition-colors duration-200"
          >
            « Older Entries
          </Link>
        )}
        
        <div className="flex gap-2">
          {/* {Array.from({ length: totalPages }, (_, i) => i + 1)
            .filter(page => {
              // Show first page, last page, current page, and pages around current
              return (
                page === 1 ||
                page === totalPages ||
                Math.abs(currentPage - page) <= 2
              );
            })
            .map((page, index, array) => (
              <React.Fragment key={page}>
                {index > 0 && array[index - 1] !== page - 1 && (
                  <span className="text-neutral-400">...</span>
                )}
                <Link
                  href={`${baseUrl}/page/${page}`}
                  className={`px-3 py-1 rounded ${
                    currentPage === page
                      ? 'bg-primary text-white'
                      : 'text-neutral-900 hover:text-primary'
                  }`}
                >
                  {page}
                </Link>
              </React.Fragment>
            ))} */}
        </div>
  
        {currentPage > 1 && (
          <Link 
            href={currentPage === 2 ? baseUrl : `${baseUrl}/${process.env.NEXT_PUBLIC_BLOG_PAGE_SLUG}/${currentPage - 1}`}
            className="text-neutral-900 transition-colors duration-200"
          >
            Next Entries »
          </Link>
        )}
      </div>
    );
  };